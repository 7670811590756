import axios from 'axios'
import { EulogiseClientConfig } from '@eulogise/client-core'
import {
  GetImageObject,
  IFilestackImageEnhancePreset,
  IImageAsset,
} from '@eulogise/core'
import moment from 'moment'

const STYLE = {
  THUMBNAIL_IMAGE_SIZE: 500,
  SM_SLIDESHOW_IMAGE_DEFAULT_WIDTH: 480,
  SM_SLIDESHOW_IMAGE_DEFAULT_HEIGHT: 270,
  MD_SLIDESHOW_IMAGE_DEFAULT_WIDTH: 960,
  MD_SLIDESHOW_IMAGE_DEFAULT_HEIGHT: 540,
  LG_SLIDESHOW_IMAGE_DEFAULT_WIDTH: 1200,
  LG_SLIDESHOW_IMAGE_DEFAULT_HEIGHT: 675,
}

interface GetImageUrlOption {
  width?: number
  height?: number
  useProdS3BucketUrl?: boolean

  // Deprecated
  caseId?: string
}
export const ImageHelper = {
  determineWidthHeightByImageScale: (
    imageWidth: number,
    imageHeight: number,
    containerWidth: number,
    containerHeight: number,
  ): {
    width: string
    height: string
  } => {
    const imageScale = imageWidth / imageHeight
    const containerScale = containerWidth / containerHeight

    if (imageScale === containerScale) {
      return {
        height: '100%',
        width: '100%',
      }
    } else if (imageScale < containerScale) {
      return {
        height: '100%',
        width: 'auto',
      }
    } else if (imageScale > containerScale) {
      return {
        height: 'auto',
        width: '100%',
      }
    }
    return {
      height: 'auto',
      width: 'auto',
    }
  },
  getClientLogo: (imageHandle: string) => {
    return `${EulogiseClientConfig.AWS_S3_URL}/clients/logos/${imageHandle}`
  },
  getImageUrl: (image: GetImageObject, options?: GetImageUrlOption) => {
    if (!image) {
      return
    }
    const { filestackHandle, url, filepath, filename, preset } = image
    const { width, height, caseId, useProdS3BucketUrl = false } = options || {}
    const enhancePresetTask = `enhance=preset:${preset}`

    if (filestackHandle) {
      let imageUrl = `${EulogiseClientConfig.FILESTACK_CDN}`
      if (preset && preset !== IFilestackImageEnhancePreset.NULL) {
        imageUrl = imageUrl + `/${enhancePresetTask}`
      }
      if (width && height) {
        imageUrl =
          imageUrl + `/resize=width:${width},height:${height}/rotate=deg:exif`
      }
      imageUrl = imageUrl + `/${filestackHandle}`
      return imageUrl
    }

    if (url) {
      // check if it is url. If it is not url, use AWS_S3_URL
      if (!url.startsWith('http')) {
        return `${EulogiseClientConfig.AWS_S3_URL}/${url}`
      }
      return url
    }

    if (filepath) {
      if (useProdS3BucketUrl) {
        return `${EulogiseClientConfig.AWS_S3_URL}/${filepath}`
      }
      return `${EulogiseClientConfig.AWS_S3_URL}/${filepath}`
    }

    // TODO: Remove filename asset fetching everywhere
    // either a FileStack.js handle or filepath should be present on every user uploaded asset
    return `${EulogiseClientConfig.AWS_S3_URL}/cases/${caseId}/gallery/${filename}`
  },

  calculateAspectRatio({
    width,
    height,
  }: {
    width: number
    height: number
  }): string {
    // Calculate the greatest common divisor (GCD) using Euclid's algorithm
    const gcd = (a: number, b: number): number => {
      if (b === 0) {
        return a
      }
      return gcd(b, a % b)
    }

    // Calculate the GCD of width and height
    const commonDivisor = gcd(width, height)

    // Simplify the aspect ratio by dividing width and height by the GCD
    const aspectWidth = width / commonDivisor
    const aspectHeight = height / commonDivisor

    // Return the aspect ratio in string format
    return `${aspectWidth}:${aspectHeight}`
  },

  getImageSizeViaFilestack: async (
    handle: string,
  ): Promise<{ width: number; height: number }> => {
    const { data } = await axios(
      `${EulogiseClientConfig.FILESTACK_CDN}/imagesize/${handle}`,
    )
    return data
  },

  getImageSizeByUrl: async (
    imageUrl: string,
  ): Promise<{ width: number; height: number }> => {
    return new Promise((resolve) => {
      ImageHelper.preloadImage(imageUrl, (event) => {
        resolve({ width: event.width, height: event.height })
      })
    })
  },

  checkImageAvailability: async (url: string): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      const img = new Image()
      img.onload = () => resolve(true) // Image is available
      img.onerror = () => reject(false) // Image is not available
      img.src = url
    })
  },

  getImageFileSize: async (url: string): Promise<string> => {
    const response = await fetch(url)
    const blob = await response.blob()
    return ImageHelper.bytesToSize(blob.size)
  },

  bytesToSize: (bytes: number) => {
    if (bytes < 1024) {
      return `${bytes}b`
    }
    const toFixedWithTrailingZero = (num: number) => {
      return num.toFixed(2).replace(/\.00$/, '')
    }
    if (bytes < 1024 * 1024) {
      return `${toFixedWithTrailingZero(bytes / 1024)}kb`
    }

    return `${toFixedWithTrailingZero(bytes / (1024 * 1024))}mb`
  },

  getImageSize: async (
    image: GetImageObject,
  ): Promise<{
    width: number
    height: number
  }> => {
    return new Promise((resolve) => {
      // @ts-ignore
      const imageUrl: string = ImageHelper.getImageUrl(image)
      ImageHelper.preloadImage(imageUrl, (event) => {
        resolve({
          width: event.width,
          height: event.height,
        })
      })
    })
  },

  getSlideshowImageUrlByCaseId: (
    image: GetImageObject,
    caseId: string,
    size: 'SM' | 'MD' | 'LG' = 'MD',
    options?: { isStaticAssets?: boolean },
  ) => {
    return ImageHelper.getImageUrl(image, {
      useProdS3BucketUrl: !!options?.isStaticAssets,
      width: Number(STYLE[`${size}_SLIDESHOW_IMAGE_DEFAULT_WIDTH`]),
      height: Number(STYLE[`${size}_SLIDESHOW_IMAGE_DEFAULT_HEIGHT`]),
      caseId,
    })
  },

  getThumbnailImageUrl: (image: GetImageObject) =>
    ImageHelper.getImageUrl(image, {
      width: Number(STYLE.THUMBNAIL_IMAGE_SIZE),
      height: Number(STYLE.THUMBNAIL_IMAGE_SIZE),
    }),

  preloadImage: (src: string, loaded: (event: any) => void) => {
    // @ts-ignore
    const img = new Image()
    img.onload = function () {
      loaded(this)
    }
    img.src = src
  },
  sortImagesDescByUpdatedDate: (
    assetImages: Array<IImageAsset>,
  ): Array<IImageAsset> => {
    const formattedImages: Array<IImageAsset> = assetImages.map(
      (img: IImageAsset) => {
        return {
          ...img,
          content: {
            ...img.content,
            createdAt: moment(img?.createdAt).unix(),
            updatedAt: moment(img?.updatedAt).unix(),
          },
        }
      },
    )
    return formattedImages.sort((a, b) => (a.updatedAt < b.updatedAt ? 1 : -1))
  },
}
