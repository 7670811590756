import { EulogiseResourceHelper } from '../../helpers/EulogiseResourceHelper'
import { CardProductHelper, UndoHelper, UtilHelper } from '@eulogise/helpers'
import {
  CardProductActionTypes,
  CardProductBorderPageType,
  CardProductPageColMode,
  EulogiseProduct,
  EulogiseRegion,
  ICardProductAction,
  ICardProductData,
  ICardProductFrameItem,
  ICardProductRow,
  ICardProductState,
  IImageAssetContent,
  ResourceFileStatus,
} from '@eulogise/core'
import { UpdateCardProductDividerAssetByRowIdPayload } from './actions'

export const baseCardProductInitialState: ICardProductState = {
  items: [],
  undoContentList: [],
  redoContentList: [],
  activeItem: null,
  activeProductTheme: undefined,
  isFetching: false,
  fontsLoading: false,
  leftPageIndex: -1,
  rightPageIndex: 0,
  leftPageContent: [],
  rightPageContent: [],
  currentPageIndex: 0,
  displayedModal: false,
  error: null,
  dirty: false,
  isUpdating: false,
}

export const BaseCardProductReducer =
  (product: EulogiseProduct) =>
  (
    state: ICardProductState = baseCardProductInitialState,
    action: ICardProductAction,
  ): ICardProductState => {
    const productType = action.payload?.productType ?? action.payload?.product
    const region: EulogiseRegion = action.payload?.region ?? EulogiseRegion.AU
    if (product !== productType) {
      return state
    }
    switch (action.type) {
      case CardProductActionTypes.UPDATE_BORDER_SETTINGS: {
        const newActiveItem: ICardProductData = {
          ...state.activeItem,
        } as ICardProductData
        const borderSettings = action.payload?.borderSettings!
        for (const [
          singleBorderSettingsType,
          singleBorderSettings,
        ] of Object.entries(borderSettings)) {
          if (
            singleBorderSettingsType === CardProductBorderPageType.FRONT_PAGE
          ) {
            let pageContent = newActiveItem.content
            if (
              CardProductHelper.getPageColModeByPageSize(
                pageContent.pageSize,
              ) === CardProductPageColMode.TWO_COLS
            ) {
              newActiveItem.content.pages[0].border = singleBorderSettings
              newActiveItem.content.pages[1].border = singleBorderSettings
            } else {
              newActiveItem.content.pages[0].border = singleBorderSettings
            }
          } else if (
            singleBorderSettingsType === CardProductBorderPageType.MIDDLE_PAGES
          ) {
            for (let i = 1; i < newActiveItem.content.pages.length - 1; i++) {
              newActiveItem.content.pages[i].border = singleBorderSettings
            }
          } else if (
            singleBorderSettingsType === CardProductBorderPageType.BACK_PAGE
          ) {
            newActiveItem.content.pages[
              newActiveItem.content.pages.length - 1
            ].border = singleBorderSettings
          }
        }
        return {
          ...state,
          activeItem: newActiveItem,
        }
      }
      case CardProductActionTypes.RESET_CARD_PRODUCT_STATE: {
        return baseCardProductInitialState
      }
      case CardProductActionTypes.CLEAN_UP_CARD_PRODUCT_UNDO_HISTORY: {
        return {
          ...state,
          undoContentList: [],
          redoContentList: [],
        }
      }
      case CardProductActionTypes.UNDO_CARD_PRODUCT_CONTENT: {
        if (!state.undoContentList || state.undoContentList?.length === 0) {
          return state
        }
        const [undoContent, ...newUndoContentList] = state.undoContentList
        const existingContent = state.activeItem?.content

        return {
          ...state,
          activeItem: {
            ...state.activeItem,
            content: JSON.parse(JSON.stringify(undoContent)),
          } as ICardProductData,
          undoContentList: newUndoContentList,
          redoContentList: UndoHelper.createUndoContentListWithNewItem(
            state.redoContentList,
            existingContent!,
          ),
        }
      }
      case CardProductActionTypes.REDO_CARD_PRODUCT_CONTENT: {
        if (!state.redoContentList || state.redoContentList?.length === 0) {
          return state
        }
        const [redoContent, ...newRedoContentList] = state.redoContentList
        const existingContent = state.activeItem?.content
        return {
          ...state,
          activeItem: {
            ...state.activeItem,
            content: JSON.parse(JSON.stringify(redoContent)),
          } as ICardProductData,
          undoContentList: UndoHelper.createUndoContentListWithNewItem(
            state.undoContentList,
            existingContent!,
          ),
          redoContentList: newRedoContentList,
        }
      }
      case CardProductActionTypes.FETCH_CARD_PRODUCTS_BY_CASE_ID_SUCCESS: {
        const items: Array<ICardProductData> = action.payload?.products!
        const activeProductTheme = action.payload?.activeProductTheme
        const activeItem: ICardProductData =
          EulogiseResourceHelper.getLatestItem(items)

        return {
          ...state,
          items,
          activeItem,
          activeProductTheme,
          isFetching: false,
        }
      }
      case CardProductActionTypes.UPDATE_CARD_PRODUCT_SPACE_ASSET_BY_ROW_ID: {
        // @ts-ignore
        const { pageIndex, rowId, asset } = action.payload
        return CardProductHelper.getUpdateBookletRowState({
          // @ts-ignore
          cardProductState: UndoHelper.recordUndoContentList(state),
          pageIndex,
          rowId,
          rowData: {
            divider: {
              asset,
            },
          },
        })
      }
      case CardProductActionTypes.UPDATE_CARD_PRODUCT_DIVIDER_ASSET_BY_ROW_ID: {
        // @ts-ignore
        const { pageIndex, rowId, divider, color } =
          action.payload as unknown as UpdateCardProductDividerAssetByRowIdPayload
        return CardProductHelper.getUpdateBookletRowState({
          // @ts-ignore
          cardProductState: UndoHelper.recordUndoContentList(state),
          pageIndex,
          rowId,
          rowData: {
            divider: {
              asset: {
                id: divider,
              },
              color,
            },
          },
        })
      }
      case CardProductActionTypes.UPDATE_CARD_PRODUCT_ICON_ASSET_BY_ROW_ID: {
        // @ts-ignore
        const { pageIndex, rowId, icon, color } = action.payload
        return CardProductHelper.getUpdateBookletRowState({
          // @ts-ignore
          cardProductState: UndoHelper.recordUndoContentList(state),
          pageIndex,
          rowId,
          rowData: {
            icon: {
              icon,
              color,
            },
          },
        })
      }
      case CardProductActionTypes.UPDATE_CARD_PRODUCT_OVERLAY: {
        const { activeItem } = state
        return UtilHelper.mergeDeepRight(state, {
          activeItem: {
            ...state.activeItem,
            content: {
              ...activeItem?.content,
              pageOverlay: action.payload?.overlay,
            },
          },
        })
      }
      case CardProductActionTypes.ENABLE_CARD_PRODUCT_OVERLAY: {
        const pageIndexes = action.payload?.pageIndexes!
        const { activeItem } = state
        return UtilHelper.mergeDeepRight(state, {
          activeItem: {
            ...state.activeItem,
            content: {
              ...activeItem?.content,
              pages: activeItem?.content?.pages.map((page, index) => {
                if (pageIndexes.includes(index)) {
                  return {
                    ...page,
                    background: {
                      ...page.background,
                      overlayEnabled: true,
                    },
                  }
                }
                return page
              }),
            },
          },
        })
      }
      case CardProductActionTypes.TOGGLE_CARD_PRODUCT_OVERLAY: {
        const pageIndex = action.payload?.pageIndex!
        const { activeItem } = state
        return UtilHelper.mergeDeepRight(state, {
          activeItem: {
            ...state.activeItem,
            content: {
              ...activeItem?.content,
              pages: activeItem?.content?.pages.map((page, index) => {
                if (index === pageIndex) {
                  return {
                    ...page,
                    background: {
                      ...page.background,
                      overlayEnabled: !page.background?.overlayEnabled,
                    },
                  }
                }
                return page
              }),
            },
          },
        })
      }
      case CardProductActionTypes.ADD_CARD_PRODUCT_PAGE_ROW_SUCCESS: {
        // @ts-ignore
        const { pageIndex, row } = action.payload
        return CardProductHelper.getAddCardProductRowState(
          // @ts-ignore
          UndoHelper.recordUndoContentList(state),
          pageIndex,
          row,
        )
      }
      case CardProductActionTypes.ADD_CARD_PRODUCT_PAGES: {
        // @ts-ignore
        const { cardProductTheme } = action.payload
        const {
          activeItem: {
            // @ts-ignore
            content: { theme },
          },
        } = state
        return CardProductHelper.getAddCardProductPagesState({
          // @ts-ignore
          cardProductState: UndoHelper.recordUndoContentList(state),
          themeId: theme!,
          productTheme: cardProductTheme,
          product: productType,
          region,
        })
      }
      case CardProductActionTypes.REMOVE_CARD_PRODUCT_PAGES: {
        return CardProductHelper.getRemoveCardProductPagesState(
          // @ts-ignore
          UndoHelper.recordUndoContentList(state),
        )
      }
      case CardProductActionTypes.UPDATE_CARD_PRODUCT_IMAGE: {
        // @ts-ignore
        const { pageIndex, rowId, columnIndex, image, frameContentItemId } =
          action.payload

        if (frameContentItemId !== undefined) {
          const row: ICardProductRow = CardProductHelper.getCardProductRow(
            state.activeItem!,
            pageIndex,
            rowId,
          )
          // @ts-ignore
          const frameItemData: ICardProductFrameItem = row.data.content
          return CardProductHelper.getUpdateBookletRowState({
            // @ts-ignore
            cardProductState: UndoHelper.recordUndoContentList(state),
            pageIndex,
            rowId,
            rowData: {
              ...row.data,
              content: CardProductHelper.getUpdatedRowContentDataForFrame({
                // @ts-ignore
                framePayload: action.payload,
                frameItemData,
              }),
            },
          })
        }
        // multiple columns
        else if (columnIndex !== undefined) {
          // updating an image in a column
          const row: ICardProductRow = CardProductHelper.getCardProductRow(
            state.activeItem!,
            pageIndex,
            rowId,
          )
          // @ts-ignore
          const items = row.data?.items
          if (!items) {
            throw new Error('column items not found')
          }
          return CardProductHelper.getUpdateBookletRowState({
            // @ts-ignore
            cardProductState: UndoHelper.recordUndoContentList(state),
            pageIndex,
            rowId,
            rowData: {
              items: [
                ...items.slice(0, columnIndex),
                { data: image },
                ...items.slice(columnIndex + 1),
              ],
            },
          })
        }
        // update single image
        return CardProductHelper.getUpdateBookletRowState({
          // @ts-ignore
          cardProductState: UndoHelper.recordUndoContentList(state),
          pageIndex,
          rowId,
          rowData: image,
        })
      }
      case CardProductActionTypes.UPDATE_CARD_PRODUCT_IMAGES: {
        // @ts-ignore
        const { pageIndex, rowId, images } = action.payload
        return CardProductHelper.getUpdateBookletRowState({
          // @ts-ignore
          cardProductState: UndoHelper.recordUndoContentList(state),
          pageIndex,
          rowId,
          rowData: {
            items: images.map((img: IImageAssetContent) => ({
              data: img,
            })),
          },
        })
      }
      case CardProductActionTypes.GENERATE_CARD_PRODUCT: {
        return UtilHelper.mergeDeepRight(state, {
          activeItem: {
            ...state.activeItem,
            fileStatus: ResourceFileStatus.PROCESSING,
          },
        })
      }
      case CardProductActionTypes.GENERATE_CARD_PRODUCT_SUCCESS: {
        return UtilHelper.mergeDeepRight(state, {
          activeItem: {
            ...state.activeItem,
            fileStatus: ResourceFileStatus.GENERATED,
          },
        })
      }
      case CardProductActionTypes.GENERATE_CARD_PRODUCT_FAILED: {
        return UtilHelper.mergeDeepRight(state, {
          activeItem: {
            ...state.activeItem,
            fileStatus: ResourceFileStatus.FAILED,
          },
        })
      }
      case CardProductActionTypes.UPDATE_CARD_PRODUCT_CONTENT_SUCCESS_COMPLETE: {
        const isAddToUndoList = action.payload?.isAddToUndoList
        return UtilHelper.mergeDeepRight(
          isAddToUndoList
            ? // @ts-ignore
              UndoHelper.recordUndoContentList(state.notAddedTmpState ?? state)
            : state,
          {
            activeItem: {
              content: {
                pages: action.payload?.pages,
              },
            },
            notAddedTmpState: isAddToUndoList
              ? null
              : // @ts-ignore
                state.notAddedTmpState ?? state,
          },
        )
      }
      case CardProductActionTypes.UPDATE_CARD_PRODUCT_PAGE_SIZE: {
        return UtilHelper.mergeDeepRight(
          // @ts-ignore
          UndoHelper.recordUndoContentList(state),
          {
            activeItem: {
              content: {
                pageSize: action.payload?.pageSize,
              },
            },
          },
        )
      }
      case CardProductActionTypes.UPDATE_CARD_PRODUCT_PAGE_MARGIN: {
        return UtilHelper.mergeDeepRight(
          // @ts-ignore
          UndoHelper.recordUndoContentList(state),
          {
            activeItem: {
              content: {
                pageMargins: action.payload?.pageMargin,
              },
            },
          },
        )
      }
      case CardProductActionTypes.CARD_PRODUCT_CONTENT_LOAD_SUCCESS:
        return {
          ...state,
          isFetching: false,
        }
      case CardProductActionTypes.CARD_PRODUCT_CONTENT_LOAD_FAIL:
        return {
          ...state,
          isFetching: false,
          error: action.error,
        }
      case CardProductActionTypes.SAVE_CARD_PRODUCT:
        return {
          ...state,
          isUpdating: true,
        }
      case CardProductActionTypes.SAVE_CARD_PRODUCT_FAIL:
        return {
          ...state,
          isUpdating: false,
          error: action.error,
        }
      case CardProductActionTypes.SAVE_CARD_PRODUCT_SUCCESS: {
        // @ts-ignore
        const { product: productData, cardProductTheme } = action.payload
        return {
          ...state,
          isFetching: false,
          isUpdating: false,
          activeItem: productData,
          activeProductTheme: cardProductTheme ?? state.activeProductTheme,
        }
      }
      case CardProductActionTypes.CREATE_CARD_PRODUCT_BY_CASE_ID:
        return {
          ...state,
        }
      case CardProductActionTypes.CREATE_CARD_PRODUCT_BY_CASE_ID_SUCCESS: {
        // @ts-ignore
        const { product: productData, cardProductTheme } = action.payload
        return {
          ...state,
          isFetching: false,
          activeItem: productData,
          activeProductTheme: cardProductTheme,
        }
      }
      case CardProductActionTypes.CREATE_CARD_PRODUCT_BY_CASE_ID_FAILED:
        return {
          ...state,
        }
      default:
        return state ?? baseCardProductInitialState
    }
  }
